import { forwardRef } from 'react';
import Box from '../../Box/Box';
import InputError from './InputError';
import { InputFloatingLabel } from './InputFloatingLabel';
import InputLabel from './InputLabel';
import {
  InputIconLeft,
  InputIconRight,
  InputProps as SharedInputProps,
  InputVariant,
  StyledInput,
  StyledWrapper,
  textColourMapper,
} from './SharedInputComponents';
import { getInputIconLeft, getInputIconRight, getInputState } from './utils';

export type InputProps = SharedInputProps;

export type InputPropsWithVariant = InputProps & { variant?: InputVariant };

// eslint-disable-next-line react/display-name
export const InputDefault = forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const {
      disabled,
      errorMessage,
      iconLeft,
      iconRight,
      id,
      label,
      mb,
      mt,
      name,
      ...rest
    } = props;

    const state = getInputState({
      disabled,
      errorMessage,
    });

    const renderedIconRight = getInputIconRight({
      iconRight,
      state,
    });

    const renderedIconLeft = getInputIconLeft({
      iconLeft,
    });

    return (
      <StyledWrapper mb={mb} mt={mt}>
        {label && (
          <InputLabel
            colour={textColourMapper[state]}
            htmlFor={id}
            label={label}
          />
        )}
        <Box position="relative">
          {renderedIconLeft && (
            <InputIconLeft state={state} type={renderedIconLeft} />
          )}
          <StyledInput
            aria-label={label}
            id={id}
            {...rest}
            disabled={disabled}
            hasIconLeft={Boolean(renderedIconLeft)}
            hasIconRight={Boolean(renderedIconRight)}
            name={name}
            ref={forwardedRef}
            state={state}
          />
          {renderedIconRight && (
            <InputIconRight state={state} type={renderedIconRight} />
          )}
        </Box>
        {state === 'error' && <InputError>{errorMessage}</InputError>}
      </StyledWrapper>
    );
  },
);

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputPropsWithVariant>(
  ({ variant = 'default', ...props }, forwardedRef) => {
    switch (variant) {
      case 'floating-label':
        return <InputFloatingLabel {...props} ref={forwardedRef} />;
      default:
        return <InputDefault {...props} ref={forwardedRef} />;
    }
  },
);

export default Input;
