import Box from '../../Box/Box';
import Text, { TextProps } from '../../Text/Text';

const InputLabel = ({
  colour,
  htmlFor,
  label,
}: {
  colour: TextProps['colour'];
  htmlFor: string | undefined;
  label: string;
}) => {
  return (
    <Box display="block" mb={4}>
      <Text as="label" colour={colour} htmlFor={htmlFor} variant="heading-6">
        {label}
      </Text>
    </Box>
  );
};

export default InputLabel;
